'use strict';

(function() {
	var onload = window.onload;

	function createTextNode(text) {
		return document.createTextNode(text);
	}

	function setStyle(node, style) {
		for (var i in style) {
			node.style[i] = style[i];
		}
	}

	window.onload = function() {
		var ieVersion = navigator.userAgent.match(/MSIE\s*((\d|\.)+)/),
			broswerCheckNode,
			ieBroswerVersionNode,
			downloadLinkNode;

		if (typeof onload === 'function') {
			onload();
		}

		if (!ieVersion || ieVersion[1] >= 9) {
			return false;
		}

		broswerCheckNode = document.createElement('div');
		ieBroswerVersionNode = document.createElement('span');
		downloadLinkNode = document.createElement('a');

		broswerCheckNode.setAttribute('class', 'broswer-check');
		ieBroswerVersionNode.setAttribute('class', 'ie-broswer-version');
		downloadLinkNode.setAttribute('href', 'http://landing-page.toursforfun.com/activity/Browser/index.html');
		downloadLinkNode.setAttribute('target', '_blank');

		setStyle(broswerCheckNode, {
		    height: '29px',
		    paddingLeft: '15px',
		    lineHeight: '29px',
		    backgroundColor: '#777',
		    color: '#fff'
		});

		setStyle(ieBroswerVersionNode, {
		    textDecoration: 'underline',
		    color: '#ffc800'
		});

		setStyle(downloadLinkNode, {
		    textDecoration: 'underline',
		    color: '#ffc800'
		});

		ieBroswerVersionNode.appendChild(createTextNode(ieVersion[1]));
		downloadLinkNode.appendChild(createTextNode('去官网下载'));
		broswerCheckNode.appendChild(createTextNode('监测到您正在使用IE'));
		broswerCheckNode.appendChild(ieBroswerVersionNode);
		broswerCheckNode.appendChild(createTextNode('浏览器，可能无法正常完成预订，建议您使用IE9以上的版本或更换其他的浏览器（如chrome），有任何问题可联系途风客服。'));
		broswerCheckNode.appendChild(downloadLinkNode);
		document.body.insertBefore(broswerCheckNode, document.body.firstChild);
	};
})();
